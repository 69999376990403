import React, { useState, useEffect } from 'react';
import { updatePassword } from '../../services/backoffice'
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const cookies = new Cookies();

const User = (props) => {

    useEffect(() => {
        props.value.setSidebarOption('none');
    });

    const [error, setError] = useState("");

    const [form, setForm] = useState({
        id: jwt_decode(cookies.get('token')).data.id,
        password1: "",
        password2: ""
    });

    // Mudar valor dos atributos
    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    // Submit do form
    const handleSubmit = (event) => {
        event.preventDefault();
        if (form.password1 !== form.password2) {
            setError("Estes atributos necessitam de ser iguais");
        } else {
            updatePassword(form)
                .then(err => errors(err));
        }
    };

    // Erros
    const errors = (erro) => {
        if (!erro.success) {
            toast.error("Problema na alteração da palavra passe");
            if (erro.error !== 1) {
                setError(erro.error);
            } else {
                setError("");
            }
        } else {
            toast.success("Palavra passe alterada com sucesso")
            setError("");
        }
    }

    return (
        <main>
            <div className='main_container'>
                <h1 className='h1 mt-3 ml-3 font-weight-normal'>Perfil</h1>
                <div className='card mt-3 ml-3 mr-3'>
                    <div className='card-body'>
                        <h4 className="font-weight-bold mt-2 ml-2">Email</h4>
                        <p className="mt-2 ml-2 mr-2">{cookies.get('email')}</p>
                        <h4 className="font-weight-bold mt-2 ml-2">Palavra Passe</h4>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mt-2 ml-2 mr-2">
                                <label htmlFor="password1">Nova Palavra Passe</label>
                                <input
                                    className={error === "" ? "form-control" : "is-invalid form-control"}
                                    type="password"
                                    name="password1"
                                    id="password1"
                                    onChange={handleChange}
                                    value={form.password1}
                                />
                                <small className="invalid-feedback">{error}</small>
                            </div>
                            <div className="form-group mt-2 ml-2 mr-2">
                                <label htmlFor="password2">Repita a Palavra Passe</label>
                                <input
                                    className={error === "" ? "form-control" : "is-invalid form-control"}
                                    type="password"
                                    name="password2"
                                    id="password2"
                                    onChange={handleChange}
                                    value={form.password2}
                                />
                                <small className="invalid-feedback">{error}</small>
                            </div>
                            <button type="submit" className="btn btn-primary mt-2 ml-2 mr-2">Alterar</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </main>
    )
}

export default User;