import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NavBar from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import Container from '../components/containers/Container';
import Password from "../components/password/Password";
import User from "../components/user/User";
import NewUser from "../components/newUser/NewUser";
import Alerts from "../components/alerts/Alerts";


const LoginRoute = (props) => {
    return (
        <Router>
            <div className="container1">
                <NavBar value={props.value} />
                <Switch>
                    <Route path="/" exact>
                        <Container value={props.value} />
                    </Route>
                    <Route path="/palavrapasse">
                        <Password value={props.value} />
                    </Route>
                    <Route path="/perfil">
                        <User value={props.value} />
                    </Route>
                    <Route path="/utilizadores">
                        <NewUser value={props.value} />
                    </Route>
                    <Route path="/alertas">
                        <Alerts value={props.value} />
                    </Route>
                </Switch>

                <Redirect to="/" />
                <Sidebar value={props.value} />
            </div>
        </Router >);
}

export default LoginRoute;