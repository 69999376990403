import React from 'react';
import { updateSendMail } from '../../services/backoffice';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Table = (props) => {

    const handleChange = (event) => {
        var bol = event.target.checked ? 1 : 0;
        updateSendMail(JSON.parse('{"id":' + event.target.id + ',"sendmail":' + bol + '}')).then(json => json.success ? toast.success("Utilizador alterado com sucesso") :toast.error("Problema na alteração do utilizador"));
    }

    const checkBox = (value) => {
        if (value.sendmail === "0") {
            return <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id={value.id} onChange={handleChange.bind(this)} />
                <label className="custom-control-label" htmlFor={value.id}></label>
            </div>
        } else {
            return <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id={value.id} defaultChecked onChange={handleChange.bind(this)} />
                <label className="custom-control-label" htmlFor={value.id}></label>
            </div>
        }
    }

    return <div className='card mt-3 ml-3 mr-3'>
        <div className='card-body'>
            <div className="row">
                <div className="col-12">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Enviar Email</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.value.map((value) => <tr key={value.id}>
                                <td>{checkBox(value)}</td>
                                <td>{value.email}</td>
                            </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
}

export default Table;