import avatar from "../../assets/images/Avatar.png";
import { logout } from "../../services/backoffice";
import { FaBars } from "react-icons/fa";
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';


const NavBar = (props) => {

    function handleLogout() {
        logout();
    }

    return (
        <nav className="navbar">
            <div className="nav_icon" onClick={() => props.value.setSidebarOpen(true)}>
                <FaBars />
            </div>
            <div></div>
            <div className="float-right">
                <div className='NavBar'>
                    <Navbar color="white" light expand="md">
                        <Collapse navbar>
                            <Nav className="mr-auto" navbar>
                                <UncontrolledDropdown>
                                    <DropdownToggle nav caret><img src={avatar} width="40" height="40" alt="user"></img></DropdownToggle>
                                    <DropdownMenu right>
                                        <Link to='/perfil' style={{ textDecoration: 'none', color: '#212529' }}>
                                            <DropdownItem>
                                                Perfil
                                            </DropdownItem>
                                        </Link>
                                        <Link to='/utilizadores' style={{ textDecoration: 'none', color: '#212529' }}>
                                            <DropdownItem>
                                                Novo Utilizador
                                            </DropdownItem>
                                        </Link>
                                        <Link to='/login' style={{ textDecoration: 'none', color: '#212529' }}>
                                            <DropdownItem onClick={handleLogout}>
                                                Terminar Sessão
                                            </DropdownItem>
                                        </Link>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;