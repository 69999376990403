import React, { Component } from 'react';
import { read } from '../../services/containers';

const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');


export class Table extends Component {

    createTable(data) {
        this.$el = $(this.el);
        this.$el.DataTable(
            {
                responsive: true,
                processing: true,
                language: {
                    url: '//cdn.datatables.net/plug-ins/1.10.22/i18n/Portuguese.json'
                },
                data: data.data,
                columns: [
                    { data: 'id' },
                    { data: 'state' },
                    { data: 'cover' },
                    { data: 'weight' },
                    { data: 'lastuse' }
                ]
            })
    }

    componentDidMount() {
        read().then(res => {this.createTable(res)});
        
        
        //window.addEventListener('resize', this._handleWindowResize);
    }

    componentWillUnmount() {
        this.$el.DataTable().destroy(true);
    }

    render() {
        return (<div>
            <table id='datatable' className='table table-striped table-bordered' ref={el => this.el = el}>
                <thead>
                    <tr>
                        <th>Contentor</th>
                        <th>Estado</th>
                        <th>Tampa</th>
                        <th>Capacidade</th>
                        <th>Último uso</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>)
    }
}

export default Table;