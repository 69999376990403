import { useState } from 'react';
import Dashboard from './routes/dashboard';
import LoginRoute from './routes/loginRoute';
import { validate } from './services/backoffice';


const App = () => {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarOption, setSidebarOption] = useState('dashboard');

  const contextValue = {
    sidebarOpen: sidebarOpen,
    setSidebarOpen: setSidebarOpen,
    sidebarOption: sidebarOption,
    setSidebarOption: setSidebarOption
  }

  if (validate()) {
    return <LoginRoute value={contextValue} />;
  }

  return (
    <div>
      <Dashboard value={contextValue} />
    </div>
  );
}

export default App;
