import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';

const SERVER_URL = window.location.origin+"/api";
const cookies = new Cookies();

// Ler todos os utilizadores de Backoffice
export async function read() {
    try {
        const res = await axios
            .get(`${SERVER_URL}/backoffice/read.php`, {
                headers: {
                    'Authorization': cookies.get('token')
                }
            });
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

// Login
export async function login(data) {
    try {
        const res = await axios
            .post(`${SERVER_URL}/backoffice/login.php`, JSON.stringify(data));
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

// Validar token
export function validate() {
    try {
        const token = jwt_decode(cookies.get('token'));
        if (token.data.email === cookies.get('email')) {
            return false;
        } else {
            return true;
        }
    } catch (e) {
        return true;
    }
}


// Terminar sessão
export function logout() {
    cookies.remove('token');
    cookies.remove('email');
    window.location.reload(false);
}

// Alterar a palavra passe do utilizador
export async function updatePassword(data) {
    try {
        const res = await axios
            .post(`${SERVER_URL}/backoffice/updatePassword.php`, data, {
                headers: {
                    'Authorization': cookies.get('token')
                }
            });
        if (res.data.jwt === false) {
            logout();
        } else {
            updateToken(res.data.jwt);
        }
        return res.data;
    } catch (err) {
        console.log("erro" + err);
    }
}

// Alterar a palavra passe do utilizador
export async function updateSendMail(data) {
    try {
        const res = await axios
            .post(`${SERVER_URL}/backoffice/updateSendMail.php`, data, {
                headers: {
                    'Authorization': cookies.get('token')
                }
            });
        if (res.data.jwt === false) {
            logout();
        } else {
            updateToken(res.data.jwt);
        }
        return res.data;
    } catch (err) {
        console.log("erro" + err);
    }
}

// Cria um utilizador
export async function create(data) {
    try {
        const res = await axios
            .post(`${SERVER_URL}/backoffice/create.php`, data, {
                headers: {
                    'Authorization': cookies.get('token')
                }
            });
        if (res.data.jwt === 0) {
            logout();
        } else {
            updateToken(res.data.jwt);
        }
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export function updateToken(token) {
    cookies.set("token", token)
}
