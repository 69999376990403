import logo from "../../assets/images/logo.png";
import { Link } from 'react-router-dom';

import { FaDumpster, FaKey, FaTimes } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Sidebar = (props) => {
    return (
        <div className={props.value.sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
            <div className="sidebar_title">
                <div className="sidebar_img">
                    <img src={logo} alt="logo" />
                    <h1 className="font-weight-bold ml-3">Smartwaste</h1>
                </div>
                <FaTimes className="nav_icon" onClick={() => props.value.setSidebarOpen(false)} />
            </div>
            <hr></hr>
            <div className="sidebar_menu">
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <div className={props.value.sidebarOption === 'container' ? "sidebar_link active_menu_link" : "sidebar_link"}>
                        <FaDumpster />
                        <span className="ml-2">Dashboard</span>
                    </div>
                </Link>
                <Link to='/palavrapasse' style={{ textDecoration: 'none' }}>
                    <div className={props.value.sidebarOption === 'password' ? "sidebar_link active_menu_link" : "sidebar_link"}>
                        <FaKey />
                        <span className="ml-2">Palavra Passe</span>
                    </div>
                </Link>
                <Link to='/alertas' style={{ textDecoration: 'none' }}>
                    <div className={props.value.sidebarOption === 'alerts' ? "sidebar_link active_menu_link" : "sidebar_link"}>
                        <MdEmail />
                        <span className="ml-2">Alertas</span>
                    </div>
                </Link>
            </div>
        </div >
    )
}

export default Sidebar;