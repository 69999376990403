import Login from "../components/login/Login";
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

const LoginRoute = (props) => {
    
    return (
        <Router>
            <Route path="/login">
                <Login value={props.value} />
            </Route>
            <Redirect to="/login" />
        </Router >);
}

export default LoginRoute;