import React, { useEffect } from 'react';
import { Table } from './Table';

const Container = (props) => {
    useEffect(() => {
        props.value.setSidebarOption('container');
    });

    return (
        <main>
            <div className='main_container'>
                <h1 className='h1 mt-3 ml-3 font-weight-normal'>Contentores</h1>
                <div className='card mt-3 ml-3 mr-3'>
                    <div className='card-body'>
                        <Table />
                    </div>
                </div>
            </div>
        </main>
    )

}

export default Container;