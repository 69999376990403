
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Cookies from 'universal-cookie';
import { logout,updateToken } from './backoffice';
const SERVER_URL = window.location.origin+"/api";
const cookies = new Cookies();

// Alterar Password
export async function updatePassword(data) {
    try {
        const res = await axios
            .post(`${SERVER_URL}/user/updatePassword.php`, JSON.stringify(data), {
                headers: {
                    'Authorization': cookies.get('token')
                }
            });
        const data_1 = res.data;
        if (data_1.jwt === 0) {
            logout();
        } else {
            updateToken(data_1.jwt);
        }
        return data_1;
    } catch (err) {
        console.log(err);
    }
};

