import React, { useState,useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { create } from '../../services/backoffice'

const NewUser = (props) => {

    useEffect(() => {
        props.value.setSidebarOption('users');
    });

    // Erro do email e palavra passe
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [form, setForm] = useState({
        email: "",
        password: ""
    });

    // Mudar valor dos atributos
    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    // Submit do form
    const handleSubmit = (event) => {
        event.preventDefault();
        create(form)
            .then(err => errors(err));
    };

    // Erros
    const errors = (erro) => {
        console.log(erro)
        if (!erro.success) {
            toast.error("Problema na criação de um utilizador");
            if (erro.email !== 1) {
                setEmail(erro.email);
            } else {
                setEmail("");
            }

            if (erro.password !== 1) {
                setPassword(erro.password);
            } else {
                setPassword("");
            }
        } else {
            toast.success("Utilizador criado com sucesso")
            setEmail("");
            setPassword("");
        }
    }

    return (
        <main>
            <div className='main_container'>
                <h1 className='h1 mt-3 ml-3 font-weight-normal'>Adicionar Utilizador</h1>
                <div className='card mt-3 ml-3 mr-3'>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mt-2 ml-2 mr-2">
                                <label htmlFor="email">Email</label>
                                <input
                                    className={email === "" ? "form-control" : "is-invalid form-control"}
                                    type="text"
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    value={form.email}
                                />
                                <small className="invalid-feedback">{email}</small>
                            </div>
                            <div className="form-group mt-2 ml-2 mr-2">
                                <label htmlFor="password">Palavra Passe</label>
                                <input
                                    className={password === "" ? "form-control" : "is-invalid form-control"}
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={handleChange}
                                    value={form.password2}
                                />
                                <small className="invalid-feedback">{password}</small>
                            </div>
                            <button type="submit" className="btn btn-primary mt-2 ml-2 mr-2">Criar</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </main>
    )
}

export default NewUser;