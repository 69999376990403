import React, { useEffect, useState } from 'react';
import { updatePassword } from "../../services/user";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const Password = (props) => {

    const [error, setError] = useState("");

    const [form, setForm] = useState({ email: "" });

    useEffect(() => {
        props.value.setSidebarOption('password');
    });

    // Mudar valor dos atributos
    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    // Submit do form
    const handleSubmit = (event) => {

        event.preventDefault();
        updatePassword(form)
            .then(err => errors(err));
    };

    // Erros
    const errors = (erro) => {
        if (!erro.success) {
            toast.error("Problema na alteração da palavra passe");
            if (erro.error !== 1) {
                setError(erro.error);
            } else {
                setError("");
            }
        } else {
            toast.success("Palavra passe alterada com sucesso")
            setError("");
        }
    }

    return (
        <main>
            <div className='main_container'>
                <h1 className='h1 mt-3 ml-3 font-weight-normal'>Alterar Palavra Passe</h1>
                <div className='card mt-3 ml-3 mr-3'>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    className={error === "" ? "form-control" : "is-invalid form-control"}
                                    type="text"
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    value={form.email}
                                />
                                <small className="invalid-feedback">{error}</small>
                            </div>
                            <button type="submit" className="btn btn-primary">Alterar</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </main>
    )
}

export default Password;