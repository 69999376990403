import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Cookies from 'universal-cookie';
import { logout,updateToken } from './backoffice';
const SERVER_URL = window.location.origin+"/api";
const cookies = new Cookies();

// Login
export async function read () {
    try {
        const res = await axios
            .get(`${SERVER_URL}/container/read.php`, {
                headers: {
                    'Authorization': cookies.get('token')
                }
            });
        if (res.data.jwt === 0) {
            logout();
        } else {
            updateToken(res.data.jwt);
        }
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
